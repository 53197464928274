@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply font-noto;
}

h1 {
  @apply text-black text-4xl font-medium;
}

h2 {
  @apply text-black text-3xl font-normal;
}

h3 {
  @apply text-black text-xl font-bold;
}

h3 {
}

table {
  @apply table-auto border-collapse border-spacing-0;
}

table,
tbody,
th,
tr,
td {
  @apply border-yellow border-2 text-center py-2;
}

#course ul,
#details ul {
  @apply list-disc list-inside;
}

.content-wrapper {
  @apply max-w-[960px] px-4 mx-auto;
}

.title {
  @apply block text-black text-4xl font-medium py-8;
}

.subtitle {
  @apply block text-black text-3xl font-normal py-8;
}

.section-heading {
  @apply block text-black text-xl font-bold;
}

.home-flex-vertical {
  @apply flex flex-col gap-4 py-8;
}

.nav-shown {
  @apply max-md:flex max-md:flex-col max-md:w-full max-md:mt-4;
}

.nav-hidden {
  @apply max-md:hidden;
}

.nav-hover {
  @apply hover:text-gamboge duration-300;
}

.social-hover {
  @apply hover:text-gamboge hover:scale-110 duration-300;
}

.image-hover {
  @apply hover:scale-105 duration-300;
}

.button-link {
  @apply px-8 py-1 border-solid border-2 rounded-lg font-medium text-base text-white hover:bg-transparent duration-300;
}

::-webkit-scrollbar {
  @apply bg-transparent;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-yellow bg-clip-content border-solid border-transparent rounded-2xl transition opacity-50;
  border-width: 6px;
}

::-webkit-scrollbar-thumb:hover {
  @apply border-4;
}

.card {
  @apply flex-shrink-0 flex flex-col items-start justify-between gap-2 p-2 rounded-lg shadow-lg bg-white bg-gradient-to-r from-white to-milk hover:scale-105 duration-300;
}

.post {
  @apply hover:bg-white hover:-translate-x-2 hover:-translate-y-4 hover:rotate-3;
}

.post:not(:first-child) {
  @apply -ml-16;
}

.post:hover ~ .post {
  @apply translate-x-16;
}

.about-text-card {
  @apply flex flex-col justify-center h-80 p-4 overflow-clip rounded-lg shadow-lg hover:scale-105 hover:shadow-2xl duration-300;
}

.form {
  perspective: 800px;
}

.form.flip .form-inner {
  transform: rotateY(-180deg);
}

.form-inner {
  @apply relative h-full rounded-2xl duration-700;
  height: 100%;
  transform-style: preserve-3d;
}

.form-side {
  @apply absolute inset-0;
  backface-visibility: hidden;
}

.form-side--front {
  @apply bg-yellow rounded-2xl shadow-lg;
}

.form-side--back {
  @apply bg-cornsilk rounded-2xl shadow-lg;
  transform: rotateY(180deg);
}
